<script setup lang="ts">
</script>

<template>
    <section class="section-inside">       
        <div class="toolbar-block">
            <slot name="left" />
        </div>   
        <div class="toolbar-block">
            <slot name="right" />            
        </div>   
    </section>
</template>

<style scoped lang="scss">
.section-inside {
    margin: 0;
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    position: relative;
}
.toolbar-block {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
}
@media all and (max-width: 950px) {
    .section-inside,
    .toolbar-block {
        flex-direction: column;
        width: 100%;
        & > :deep(*) {
            width: 100%;
        }
    }
}
</style>